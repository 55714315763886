.container {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
}
.container .text {
  color: #ccc;
  padding-right: 6px;
}
.container .count0 {
  color: #ccc;
}