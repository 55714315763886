.container {
  position: relative;
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
}
.container .toolBar {
  padding: 24px 24px 0;
  background-color: #fff;
  display: flex;
  justify-content: end;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.container .actions {
  color: #222;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.container .actions .action {
  cursor: pointer;
}
.container .articleContainer {
  height: 100%;
  padding: 0 24px 24px;
  overflow-y: auto;
}
.container .articleContainer .sectionContent {
  margin-top: 8px;
  padding: 4px;
  background-color: #fff;
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  resize: none;
}
.container .articleContainer .articleTitle {
  padding: 0 8px;
  color: #222;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}
.container .articleContainer .sectionContainer {
  padding: 8px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 8px;
}
.container .articleContainer .sectionContainer .sectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  scroll-margin-top: 46px;
}
.container .articleContainer .sectionContainer .sectionTitle .sectionTitleText {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.container .articleContainer .sectionContainer .sectionMiniTitle {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  scroll-margin-top: 46px;
}
.container .articleContainer .sectionContainer .sectionMiniTitle.sectionMiniTitleSummary {
  margin-bottom: -8px;
}
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleText {
  color: #222;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
}
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions .left,
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions .right {
  cursor: pointer;
}
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions .left.disabled,
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions .right.disabled {
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
}
.container .articleContainer .sectionContainer .sectionMiniTitle .sectionMiniTitleActions .mid {
  color: #999;
}