@charset "UTF-8";
.generate-outline-form {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 76px;
}
.generate-outline-form *::-webkit-scrollbar {
  display: none;
  height: 6px;
  width: 6px; /* 设置滚动条的宽度 */
}
.generate-outline-form .arco-steps-item {
  line-height: 12px;
}
.generate-outline-form .arco-steps-item .arco-steps-item-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.generate-outline-form .arco-steps-item .arco-steps-item-title::after {
  top: 6px !important;
}
.generate-outline-form .arco-steps-item .arco-steps-item-icon .arco-steps-icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.generate-outline-form .arco-steps-item.arco-steps-item-active .arco-steps-icon {
  background-color: rgb(var(--primary-6));
  color: #ffffff;
}
.generate-outline-form .arco-steps-item.arco-steps-item-active .arco-steps-item-title {
  color: rgb(var(--primary-6));
}
.generate-outline-form .arco-btn-text.arco-btn:hover {
  background-color: white;
}
.generate-outline-form .arco-btn.button {
  border-radius: 8px;
  color: rgb(var(--primary-6));
  background-color: #ffffff;
  border: 1px solid rgba(22, 93, 255, 0.2);
  font-size: 12px;
}
.generate-outline-form .arco-btn.button:hover {
  color: rgb(var(--primary-6));
  border: 1px solid rgba(22, 93, 255, 0.2);
}
.generate-outline-form .arco-btn.button.small {
  font-size: 10px;
}
.generate-outline-form .generate-outline-form-footer {
  height: 76px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1019607843);
  background-color: white;
}
.generate-outline-form .generate-outline-form-footer .arco-btn {
  height: 100%;
  border-radius: 8px;
  min-width: 128px;
}
.generate-outline-form .generate-outline-form-footer.no-border {
  border: none;
}
.generate-outline-form .arco-btn {
  border-radius: 8px;
}
.generate-outline-form .arco-btn.default {
  background-color: #ffffff;
  border: #c4c4c4 1px solid;
  height: 40px;
}
.generate-outline-form .arco-btn.small {
  background-color: #ffffff;
  border: #c4c4c4 1px solid;
  height: 28px;
}
.generate-outline-form .arco-btn.icon-button {
  height: 40px;
  width: 40px;
  padding: 9px;
  box-sizing: border-box;
}
.generate-outline-form .icon-button .arco-btn-disabled {
  height: 40px;
  width: 40px;
}
.generate-outline-form .arco-btn.icon-button.small {
  height: 28px;
  width: 28px;
  padding: 3px;
}
.generate-outline-form .arco-btn.text-icon-button {
  color: #666666;
  height: 20px;
  width: 20px;
}
.generate-outline-form .arco-btn.text-icon-button:hover {
  color: #165dff;
}
.generate-outline-form .arco-btn.text-icon-button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
  color: #666666;
}
.generate-outline-form .outline-button {
  border: rgba(22, 93, 255, 0.2) 1px solid;
  color: #165dff;
}
.generate-outline-form .outline-button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}
.generate-outline-form .generate-button {
  background: rgba(22, 93, 255, 0.1019607843);
  border-color: rgba(22, 93, 255, 0.1019607843);
  padding-left: 6px;
  padding-right: 6px;
  height: 40px;
}

.generate-outline-form-item-flash {
  animation: flash 1s 3;
}

@keyframes flash {
  0% {
    border: red !important;
  }
  50% {
    border: inherit;
  }
  100% {
    border: red !important;
  }
}
.lang-select .arco-select-view {
  line-height: 36px;
  min-width: 92px;
  height: 36px;
  border-radius: 8px;
  padding: 0 24px 0 16px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 232, 240);
}
.lang-select .arco-select-view-value {
  color: rgb(27, 37, 89);
  font-size: 14px;
  font-weight: 400;
}

.tool-bar .ai-button {
  padding-left: 8px;
  padding-right: 8px;
}
.tool-bar .lang-select {
  width: 86px;
  border-radius: 8px;
}