.keywords-select {
  width: 100%;
  min-height: 98px;
  height: fit-content;
  padding: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-fill-2);
  background-color: var(--color-fill-2);
}
.keywords-select:hover {
  cursor: text;
  background-color: var(--color-fill-3);
}
.keywords-select:focus {
  background-color: var(--color-bg-2);
  border-color: rgb(var(--primary-6));
}
.keywords-select .select-item {
  display: flex;
  align-items: center;
  min-width: 85px;
  height: 32px;
  padding: 8px;
  gap: 6px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}
.keywords-select .select-item.checked {
  border: 1px solid var(--Color, #165dff);
}
.keywords-select .arco-textarea {
  margin-top: 8px;
  padding: 0;
  border-width: 0;
  background-color: inherit;
  resize: none;
  transition: none;
  border-radius: 0;
}
.keywords-select .arco-textarea:focus {
  background-color: inherit;
}
.keywords-select .arco-textarea:hover {
  background-color: inherit;
}