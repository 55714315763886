.arco-textarea {
  border-radius: 8px;
}

.section-title .arco-input-group-prefix {
  font-weight: 400;
  font-size: 14px;
}
.section-title .arco-input-inner-wrapper.arco-input-inner-wrapper-has-prefix {
  border-radius: 8px;
}
.section-title .arco-input-inner-wrapper.arco-input-inner-wrapper-has-prefix input {
  padding-left: 4px;
}